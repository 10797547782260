var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip,
      expression: "tooltip"
    }]
  }, [_c('div', {
    staticClass: "sickness-header"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.date))])]), _c('div', {
    staticClass: "sickness-stats"
  }, [_c('span', {
    staticClass: "duration"
  }, [_vm.isLoading ? _c('LoadingDots') : _c('h3', [_vm._v(_vm._s(_vm.duration))])], 1), _c('span', {
    class: _vm.changeClass
  }, [_vm.isLoading ? _c('LoadingDots') : _c('h3', [_vm._v(_vm._s(_vm.variation))])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }