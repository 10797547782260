<template>
  <div>
    <AssetSickPeriodDetailStyled @click="toggleIssueTab">
      <div class="action">
        <ChevronRightIcon class="icon" :class="{ rotate: isDetailsExpanded }" />
      </div>
      <div class="count">
        <CircleBadge class="filterButton" :label="assetIssue.issues.length" size="M" />
      </div>
      <div class="code">
        <span>#{{ assetIssue.code }}</span>
      </div>
      <div class="label">
        <span>{{ assetIssue.label }}</span>
      </div>
    </AssetSickPeriodDetailStyled>
    <IssueDetailsStyled v-if="isDetailsExpanded">
      <header>
        <KeyValueV2Atom :label="'SPN'" :value="assetIssue.issues[0].spn" />
        <KeyValueV2Atom :label="'FMI'" :value="assetIssue.issues[0].fmi" />
        <KeyValueV2Atom :label="'PGN'" :value="assetIssue.issues[0].pgn || '-'" />
      </header>
      <TextSectionAreaStyled>
        <TextSectionMolecule
          class="summary textSection"
          :title="$t('maintenance.summary')"
          :text="assetIssue.issues[0].summary || $t('messages.noDataAvailable')"
        />
        <TextSectionMolecule
          class="details textSection"
          :title="$t('maintenance.details')"
          :text="assetIssue.issues[0].description || $t('messages.noDataAvailable')"
        />
        <TextSectionMolecule
          class="action textSection"
          :isMarkdown="true"
          :title="$t('maintenance.recommendedAction')"
          :text="assetIssue.issues[0].action || $t('messages.noDataAvailable')"
        />
      </TextSectionAreaStyled>
      <div class="signal-nav" v-if="hasSignalReadPermission">
        <GoToAtom :to="routeName" :label="label" :target="'_blank'" :loading="$apollo.loading" />
      </div>
    </IssueDetailsStyled>
  </div>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import { ChevronRightIcon } from 'vue-feather-icons'
import { buttonReset } from '@styles/mixins'
import KeyValueV2Atom from '@/components/Atomic/Atoms/KeyValueV2Atom'
import TextSectionMolecule from '@/components/Atomic/Molecules/TextSectionMolecule'
import GoToAtom from '@/components/Atomic/Atoms/GoToAtom.vue'
import permissionsMixin from '@/mixins/permissions'
import { getLocale } from '@/utils/locale'
import { secondsTohhmmss } from '@/utils/filters/time'

import ASSET_ISSUE_RAW_DATA_QUERY from '#/graphql/operations/maintenance/assetIssueRawDataQuery.gql'

const AssetSickPeriodDetailStyled = styled('div')`
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template-columns: 1rem min-content max-content 1fr;
  grid-template-areas: 'chevron count code label';
  grid-gap: 0.75rem;
  background: ${({ theme }) => theme.colors.solidBG};
  border-radius: 0.5rem;
  > .action {
    grid-area: chevron;
    ${p => buttonReset(p)}
    color: ${p => p.theme.colors.primary};
    padding: 0;
    &:hover {
      color: ${p => p.theme.colors.primaryLighter};
    }
    .icon {
      flex-shrink: 0;
      overflow: hidden;
      transition: transform 0.25s;
      &.rotate {
        transform: rotate(90deg);
      }
    }
  }
  > .count {
    grid-area: count;
    justify-self: center;
  }
  .code,
  .label,
  .action {
    display: flex;
    gap: 0.25rem;
  }

  .code {
    grid-area: code;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
  }
  .label {
    grid-area: label;
  }
`

const IssueDetailsStyled = styled('div')`
  padding-top: 0.5rem;
  display: grid;
  grid-template-rows: min-content 1fr;
  header {
    display: flex;
    gap: 3rem;
    flex-direction: row;
    > div {
      .label {
        font-weight: 700;
        font-size: 14px;
        align-items: center;
      }
      .value {
        font-weight: 400;
        font-size: 16px;
        align-self: center;
        color: ${({ theme }) => theme.colors.textActivePrimary};
      }
    }
    padding: 1rem;
    border-top: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  }
  .signal-nav {
    justify-self: baseline;
    padding: 0.5rem 1rem;
  }
`
const TextSectionAreaStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content min-content;
  grid-gap: 0.5rem;
  padding: 1rem 0;
  grid-template-areas:
    'summary'
    'details'
    'action';
  .summary {
    grid-area: summary;
  }
  .details {
    grid-area: details;
  }
  .action {
    grid-area: action;
  }
  .textSection {
    > div {
      border-bottom: unset;
      font-size: 12px;
      font-weight: 700;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      > div > p {
        margin: unset;
      }
    }
  }
`

export default {
  inject: ['theme', 'uiSettings'],
  mixins: [permissionsMixin],
  props: {
    assetIssue: {
      type: Object,
      required: true,
    },
  },
  filters: {
    secondsTohhmmss,
  },
  data() {
    return {
      isDetailsExpanded: false,
      assetIssueRawData: [],
    }
  },
  components: {
    AssetSickPeriodDetailStyled,
    CircleBadge,
    ChevronRightIcon,
    IssueDetailsStyled,
    KeyValueV2Atom,
    TextSectionAreaStyled,
    TextSectionMolecule,
    GoToAtom,
  },
  computed: {
    assetId() {
      return this.$route?.params?.id
    },
    signalIds() {
      return this.assetIssueRawData?.relatedSignals.map(({ signal }) => signal?.id)
    },
    routeName() {
      const signals = this.signalIds?.toString()
      const startTS = this.assetIssueRawData?.start ? Date.parse(this.assetIssueRawData?.start) : null
      return {
        name: 'signals',
        query: {
          assetId: this.assetId,
          ...(signals && {
            signalIds: signals,
            ...(startTS && { start: startTS }),
          }),
        },
      }
    },
    label() {
      return this.$tc('actions.seeRelatedSignals')
    },
    hasSignalReadPermission() {
      return this.permissions?.read?.find(p => p.name === 'signal_read')
    },
    issueId() {
      return this.assetIssue?.issues[0].id
    },
  },
  methods: {
    toggleIssueTab() {
      this.isDetailsExpanded = !this.isDetailsExpanded
    },
  },
  apollo: {
    assetIssueRawData: {
      query: ASSET_ISSUE_RAW_DATA_QUERY,
      variables() {
        return {
          where: {
            id: this.issueId,
            locale: getLocale(this.uiSettings.dates),
          },
        }
      },
      manual: true,
      result({ data }) {
        this.assetIssueRawData = data?.assetIssue
      },
    },
  },
}
</script>
