var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistoricalAssetSickPeriodWrapperStyled', [_c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "heading",
    attrs: {
      "data-testid": "sickPeriod-header"
    }
  }, [_vm._v(_vm._s(_vm.$t('historicalAssetHealth.sickPeriod')))]), _c('span', {
    staticClass: "sickDate",
    attrs: {
      "data-testid": "sickPeriod-value"
    }
  }, [_vm._v(_vm._s(_vm.getSickPeriodDateTime))])]), _vm.loading ? _c('TalpaLoaderWrapper') : [_vm.sicknessSummary && _vm.sicknessSummary.sickIntervals.length && _vm.sickPeriod ? _c('HistoricalAssetAggregatedHourlyHealthDataMolecule', {
    attrs: {
      "sicknessSummary": _vm.sicknessSummary,
      "sickPeriod": _vm.sickPeriod,
      "sickHoursOfTheDay": _vm.sickHoursOfTheDay
    },
    on: {
      "onHourChange": function onHourChange($event) {
        return _vm.$emit('onHourChange', $event);
      }
    }
  }) : _vm._e(), _vm.groupedIssues.size > 0 ? _vm._l(_vm.groupedIssues, function (_ref) {
    var key = _ref[0],
      value = _ref[1];
    return _c('HistoricalAssetHourlyHealthDataMolecule', {
      key: key,
      attrs: {
        "sickPeriod": _vm.sickPeriod,
        "assetIssue": value
      }
    });
  }) : _c('div', {
    staticClass: "noData"
  }, [_vm._v(_vm._s(_vm.$t('messages.noDataForSelectedTime')))])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }