<template>
  <HistoricalAssetHealthDetailHeaderStyled id="sickness-info">
    <div class="header-row full-width">
      <div class="item heading">{{ $t('historicalAssetHealth.title') }}</div>
      <span class="item health-status">
        <span class="status">
          <span class="legend healthy" />
          <span class="label">{{ $t('historicalAssetHealth.status.healthy') }}</span>
        </span>
        <span class="status">
          <span class="legend sick">
            <span class="stripe" />
            <span class="stripe" />
            <span class="stripe" />
            <span class="stripe" />
          </span>

          <span class="label">{{ $t('historicalAssetHealth.status.sick') }}</span>
        </span>
        <span class="status">
          <span class="legend unknown" />
          <span class="label">{{ $t('historicalAssetHealth.status.unknown') }}</span>
        </span>
        <div class="info">
          <VPopover
            :placement="'bottom-end'"
            :open.sync="isActive"
            :popperOptions="popperOptions"
            :popoverClass="'sicknessDetailsPopOver'"
            :container="'#sickness-info'"
            :trigger="'hover'"
            class="sicknessPopover"
          >
            <InfoIcon size="20" class="icon" />
            <template v-slot:popover>
              <PopoverInnerWrapperStyled>
                <div class="content">
                  <div>
                    <span v-html="$t('historicalAssetHealth.tooltip.healthyPeriod.info')" />
                  </div>
                  <div>
                    <span v-html="$t('historicalAssetHealth.tooltip.sickPeriod.info')" />
                  </div>
                  <div>
                    <span v-html="$t('historicalAssetHealth.tooltip.unknownPeriod.info')" />
                  </div>
                </div>
              </PopoverInnerWrapperStyled>
            </template>
          </VPopover>
        </div>
      </span>
    </div>
  </HistoricalAssetHealthDetailHeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { InfoIcon } from 'vue-feather-icons'
import { VPopover, VClosePopover } from 'v-tooltip'
import chroma from 'chroma-js'

const HistoricalAssetHealthDetailHeaderStyled = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  padding: 1rem 2rem;

  .header-row {
    display: flex;
    align-items: center;
  }

  .full-width {
    justify-content: space-between;
  }

  .heading {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .health-status {
    display: flex;
    font-size: 0.75rem;
    gap: 1rem;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .legend {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1.25px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .healthy {
    background-color: ${({ theme }) => theme.colors.atomic.healthyFill};
    border-color: ${({ theme }) => theme.colors.atomic.healthyBorder};
  }
  .sick {
    background-color: ${({ theme }) => theme.colors.atomic.sickFill};
    border-color: ${({ theme }) => theme.colors.atomic.sickBorder};
  }
  .stripe {
    position: absolute;
    width: 1px; /* Width of the stripes */
    height: 17px; /* Height of the stripes, extending beyond the circle's diameter */
    background-color: ${({ theme }) => theme.colors.atomic.sickBorder}; /* Color of the stripes */
    top: -2px; /* Positioning to make sure the stripes cover the circle vertically */
  }

  .stripe:nth-child(1) {
    left: 2px;
    transform: rotate(208deg);
  }

  .stripe:nth-child(2) {
    left: 6px;
    transform: rotate(208deg);
  }

  .stripe:nth-child(3) {
    left: 10px;
    transform: rotate(208deg);
  }
  .stripe:nth-child(4) {
    left: 14px;
    transform: rotate(208deg);
  }
  .unknown {
    background-color: ${({ theme }) => theme.colors.atomic.unknownFill};
    border-color: ${({ theme }) => theme.colors.atomic.unknownBorder};
    .label {
      text-align: center;
    }
  }

  .info {
    display: flex;
    justify-content: center;
    > .sicknessPopover .trigger {
      display: flex !important;
      align-items: center;
    }
    .icon {
      color: ${({ theme }) => theme.colors.atomic.primary};
    }
  }

  .mttr {
    margin-right: 1rem;
  }
`
const PopoverInnerWrapperStyled = styled('div')`
  width: 400px;
  justify-content: start;
  box-sizing: border-box;
  padding: 0.5rem;
  color: ${p => p.theme.colors.navFontNormal};
  background-color: ${p => chroma(p.theme.colors.solidBG).alpha(1).css()};
  border-radius: 10px;
  .content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > div {
      font-weight: 400;
      font-size: 12px;
      ul {
        list-style-type: disc;
        margin: 0.25rem 0;
        padding: 0 1.75rem;
      }
    }
  }
`
export default {
  inject: ['theme'],
  components: {
    HistoricalAssetHealthDetailHeaderStyled,
    InfoIcon,
    VPopover,
    PopoverInnerWrapperStyled,
  },
  directives: {
    closePopover: VClosePopover,
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
}
</script>
