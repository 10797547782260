<template>
  <HistoricalAssetSickPeriodWrapperStyled>
    <div class="header">
      <span class="heading" data-testid="sickPeriod-header">{{ $t('historicalAssetHealth.sickPeriod') }}</span>
      <span class="sickDate" data-testid="sickPeriod-value">{{ getSickPeriodDateTime }}</span>
    </div>
    <TalpaLoaderWrapper v-if="loading" />
    <template v-else>
      <HistoricalAssetAggregatedHourlyHealthDataMolecule
        :sicknessSummary="sicknessSummary"
        :sickPeriod="sickPeriod"
        :sickHoursOfTheDay="sickHoursOfTheDay"
        @onHourChange="$emit('onHourChange', $event)"
        v-if="sicknessSummary && sicknessSummary.sickIntervals.length && sickPeriod"
      />
      <template v-if="groupedIssues.size > 0">
        <HistoricalAssetHourlyHealthDataMolecule
          v-for="[key, value] in groupedIssues"
          :key="key"
          :sickPeriod="sickPeriod"
          :assetIssue="value"
        />
      </template>
      <div v-else class="noData">{{ $t('messages.noDataForSelectedTime') }}</div>
    </template>
  </HistoricalAssetSickPeriodWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import HistoricalAssetHourlyHealthDataMolecule from '@/components/Atomic/Molecules/HistoricalAssetHealth/HistoricalAssetHourlyHealthDataMolecule'
import HistoricalAssetAggregatedHourlyHealthDataMolecule from '@/components/Atomic/Molecules/HistoricalAssetHealth/HistoricalAssetAggregatedHourlyHealthDataMolecule.vue'
import { TalpaLoaderWrapper } from '@common/components'
import { getLongDate, getFromToTime } from '@/utils/time'
import { getLocale } from '@/utils/locale'
import { orderBy } from 'lodash'
import { Duration } from 'luxon'

const HistoricalAssetSickPeriodWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .heading {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .sickDate {
      font-size: 1rem;
      font-weight: 400;
    }
  }
  .loadingSickness {
    float: left;
    padding: 1rem 0rem 1rem 2rem;
  }
  .noData {
    font-size: 0.8rem;
  }
`
export default {
  inject: ['theme', 'uiSettings'],
  props: {
    sickPeriod: {
      type: Object,
      required: true,
    },
    assetIssues: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    sicknessSummary: {
      type: Object || null,
      required: true,
    },
    sickHoursOfTheDay: {
      type: Array,
      required: true,
    },
  },
  components: {
    HistoricalAssetSickPeriodWrapperStyled,
    HistoricalAssetHourlyHealthDataMolecule,
    HistoricalAssetAggregatedHourlyHealthDataMolecule,
    TalpaLoaderWrapper,
  },
  computed: {
    getSickPeriodDateTime() {
      const locale = getLocale(this.uiSettings.dates)
      return (
        getLongDate(this.sickPeriod.start, locale) +
        ', ' +
        getFromToTime(this.sickPeriod.start, this.sickPeriod.end) +
        ', ' +
        this.getMinutesFromSeconds(this.totalDuration)
      )
    },
    totalDuration() {
      const durations = this.sicknessSummary?.sickIntervals.map(interval => interval.duration)
      const totalDuration = durations.reduce((total, duration) => total + duration, 0)
      return totalDuration
    },
    groupedIssues() {
      const issueGroupssMap = new Map()
      const orderedIssues = orderBy(this.assetIssues, 'isActive', 'desc')
      return orderedIssues.reduce((acc, assetIssue) => {
        const severities = [...assetIssue.severities].sort((a, b) => a < b)
        const id = `${assetIssue.code}`
        let issueGroup = acc.get(id)
        if (!issueGroup) {
          issueGroup = {
            id,
            code: assetIssue.code,
            component: assetIssue.component,
            issues: [assetIssue],
            highestSeverity: assetIssue.highestSeverity,
            severities: severities,
            label: assetIssue.label,
            description: assetIssue.description,
            duration: assetIssue.duration,
            activeIssues: [],
          }
          acc.set(id, issueGroup)
        } else {
          issueGroup.duration = issueGroup.duration + assetIssue.duration
          issueGroup.issues.push(assetIssue)
        }
        if (assetIssue.isActive) {
          issueGroup.activeIssues = assetIssue.severities
        }
        return acc
      }, issueGroupssMap)
    },
  },
  methods: {
    getMinutesFromSeconds(seconds) {
      const minutes = Duration.fromObject({ seconds }).as('minutes').toFixed(1)
      return `${minutes} ${this.$t('shortMinute')}`
    },
  },
}
</script>
