var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TextSectionMoleculeStyled', [_c('TitleWithBorderAtom', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm.isMarkdown ? _c('VueMarkdown', {
    attrs: {
      "source": _vm.text
    }
  }) : _c('LineClampedTextAtom', {
    attrs: {
      "text": _vm.text,
      "linesClamped": _vm.linesClamped
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }