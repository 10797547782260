var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AssetSickPeriodDetailStyled', {
    on: {
      "click": _vm.toggleIssueTab
    }
  }, [_c('div', {
    staticClass: "action"
  }, [_c('ChevronRightIcon', {
    staticClass: "icon",
    class: {
      rotate: _vm.isDetailsExpanded
    }
  })], 1), _c('div', {
    staticClass: "count"
  }, [_c('CircleBadge', {
    staticClass: "filterButton",
    attrs: {
      "label": _vm.assetIssue.issues.length,
      "size": "M"
    }
  })], 1), _c('div', {
    staticClass: "code"
  }, [_c('span', [_vm._v("#" + _vm._s(_vm.assetIssue.code))])]), _c('div', {
    staticClass: "label"
  }, [_c('span', [_vm._v(_vm._s(_vm.assetIssue.label))])])]), _vm.isDetailsExpanded ? _c('IssueDetailsStyled', [_c('header', [_c('KeyValueV2Atom', {
    attrs: {
      "label": 'SPN',
      "value": _vm.assetIssue.issues[0].spn
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": 'FMI',
      "value": _vm.assetIssue.issues[0].fmi
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": 'PGN',
      "value": _vm.assetIssue.issues[0].pgn || '-'
    }
  })], 1), _c('TextSectionAreaStyled', [_c('TextSectionMolecule', {
    staticClass: "summary textSection",
    attrs: {
      "title": _vm.$t('maintenance.summary'),
      "text": _vm.assetIssue.issues[0].summary || _vm.$t('messages.noDataAvailable')
    }
  }), _c('TextSectionMolecule', {
    staticClass: "details textSection",
    attrs: {
      "title": _vm.$t('maintenance.details'),
      "text": _vm.assetIssue.issues[0].description || _vm.$t('messages.noDataAvailable')
    }
  }), _c('TextSectionMolecule', {
    staticClass: "action textSection",
    attrs: {
      "isMarkdown": true,
      "title": _vm.$t('maintenance.recommendedAction'),
      "text": _vm.assetIssue.issues[0].action || _vm.$t('messages.noDataAvailable')
    }
  })], 1), _vm.hasSignalReadPermission ? _c('div', {
    staticClass: "signal-nav"
  }, [_c('GoToAtom', {
    attrs: {
      "to": _vm.routeName,
      "label": _vm.label,
      "target": '_blank',
      "loading": _vm.$apollo.loading
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }