<template>
  <TimePeriodSwitcherStyled>
    <IconStyled @click="switchPeriod(-1)" :data-testid="`prev-timePeriod-${unit}`" :class="{ disabled: disablePrevNav }">
      <ChevronLeftIcon class="icon prev" />
    </IconStyled>
    <strong class="date" :data-testid="`value-timePeriod-${unit}`">{{ displayDate }}</strong>
    <IconStyled @click="switchPeriod(1)" :data-testid="`next-timePeriod-${unit}`" :class="{ disabled: disableNextNav }">
      <ChevronRightIcon class="icon next" />
    </IconStyled>
  </TimePeriodSwitcherStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'
import { units, switchPeriod, ALLOWED_UNITS, isFuturePeriod, switchDate } from '@/utils/SwitchComponent/TimePeriodSwitcher'
import { getLocale } from '@/utils/locale'
import { flexCenter } from '@styles/mixins'
import { DateTime } from 'luxon'

const TimePeriodSwitcherStyled = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  font-size: 0.75rem;
  line-height: 1rem;

  .prev {
    padding: 0rem 0.5rem 0rem 0rem;
  }
  .next {
    padding: 0rem 0rem 0rem 0.5rem;
  }
  .date {
    margin-bottom: 0.15rem;
    min-width: 2rem;
  }
`

const IconStyled = styled('div')`
  ${flexCenter}
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.primaryActive};
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
export default {
  inject: ['uiSettings'],
  props: {
    unit: {
      type: String,
      required: true,
      validator: value => ALLOWED_UNITS.includes(value),
    },
    date: {
      type: Object,
      required: true,
    },
    validDates: {
      // This will allow the user to navigate to only those dates
      type: Array,
      required: false,
    },
  },
  components: {
    TimePeriodSwitcherStyled,
    IconStyled,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      dateTime: this.date,
      units: units,
      currentUnit: {},
    }
  },
  computed: {
    displayDate() {
      return this.currentUnit.display(this.dateTime, getLocale(this.uiSettings.dates))
    },
    isFuturePeriod() {
      return isFuturePeriod(this.dateTime.end, this.currentUnit.key)
    },
    sortedValidDates() {
      if (this.currentUnit.key === 'hour') {
        return this.validDates.map(date => DateTime.fromISO(date)).sort((a, b) => a.hour - b.hour)
      } else {
        return this.validDates.map(date => DateTime.fromISO(date)).sort((a, b) => a - b)
      }
    },
    isAtStartOfDay() {
      if (!this.validDates?.length) return false
      const startDate = DateTime.fromISO(this.date.start)
      return startDate.equals(this.sortedValidDates[0])
    },
    isAtEndOfDay() {
      if (!this.validDates?.length) return false
      const startDate = DateTime.fromISO(this.date.start)
      return startDate.equals(this.sortedValidDates[this.sortedValidDates?.length - 1])
    },
    disablePrevNav() {
      const startDate = DateTime.fromISO(this.date.start)
      return (
        (this.currentUnit.key === 'day' &&
          (startDate.day === 1 || startDate.startOf('day').equals(startDate.startOf('month')) || this.isAtStartOfDay)) ||
        (this.currentUnit.key === 'hour' && this.isAtStartOfDay)
      )
    },
    disableNextNav() {
      const endDate = DateTime.fromISO(this.date.end)
      return (
        (this.currentUnit.key === 'day' && (endDate.equals(endDate.endOf('month')) || this.isAtEndOfDay)) ||
        (this.currentUnit.key === 'hour' && this.isAtEndOfDay) ||
        (this.currentUnit.key === 'month' && this.isFuturePeriod)
      )
    },
  },
  created() {
    this.currentUnit = this.units.find(unit => unit.key === this.unit)
  },
  methods: {
    trackEvent(trackingIdPrefix, direction, unit, value) {
      const action = direction === 1 ? 'next' : 'prev'
      const trackingId = trackingIdPrefix + `${unit}-${action}`
      this.$posthog.capture(trackingId, value)
    },
    switchPeriod(direction) {
      if (this.isFuturePeriod && direction === 1) {
        return
      }
      let newStart = null
      if (this.currentUnit.key === 'day' || (this.currentUnit.key === 'hour' && this.sortedValidDates.length > 0)) {
        newStart = switchDate(this.dateTime.start, direction, this.sortedValidDates)
      } else {
        newStart = switchPeriod(this.dateTime.start, this.currentUnit.key, direction)
      }
      const newEnd = newStart.endOf(this.currentUnit.key)
      this.trackEvent(`Historical-Asset-Health-`, direction, this.currentUnit.key, { from: this.dateTime.start, to: newStart })
      this.dateTime = { start: newStart, end: newEnd }
      this.$emit('changeTimePeriod', this.dateTime)
    },
  },
}
</script>
