var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistoricalAssetMonthlyHealthDataStyled', [_vm.loading ? _c('TalpaLoaderWrapper') : [_vm.month && _vm.data.length ? [_c('TimePeriodSwitcherMolecule', {
    attrs: {
      "date": _vm.month,
      "unit": 'month'
    },
    on: {
      "changeTimePeriod": _vm.onChangeTimePeriod
    }
  }), _c('CustomChartAtom', {
    attrs: {
      "data": _vm.seriesData(),
      "categories": _vm.categories,
      "types": _vm.types,
      "tooltip": _vm.tooltip,
      "xAxisLabelGenerator": _vm.xAxisLabelGenerator
    },
    on: {
      "itemClick": _vm.onItemClick
    }
  })] : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }