var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistoricalAssetDailyHealthDataStyled', [_vm.loading ? _c('TalpaLoaderWrapper') : _vm._e(), !_vm.loading ? _c('TimePeriodSwitcherMolecule', {
    attrs: {
      "date": _vm.dayOfMonth,
      "unit": 'day',
      "validDates": _vm.sickDaysOfTheMonth
    },
    on: {
      "changeTimePeriod": _vm.onChangeTimePeriod
    }
  }) : _vm._e(), !_vm.loading ? _c('CustomChartAtom', {
    attrs: {
      "data": _vm.seriesData(),
      "categories": _vm.categories,
      "types": _vm.types,
      "tooltip": _vm.tooltip,
      "xAxisLabelGenerator": _vm.xAxisLabelGenerator
    },
    on: {
      "itemClick": _vm.onItemClick
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }