<template>
  <OverviewWrapperStyled>
    <TalpaLoaderWrapper v-if="$apollo.queries.asset.loading" />
    <template v-else>
      <template v-if="asset">
        <AssetHeaderOrganism :asset="asset" />
        <HistoricalAssetHealthDetailOrganism :assetId="asset.id" />
      </template>
      <div v-else class="noData">{{ $t('messages.noData') }}</div>
    </template>
  </OverviewWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaLoaderWrapper } from '@common/components'
import AssetHeaderOrganism from './AssetHeaderOrganism'
import HistoricalAssetHealthDetailOrganism from '@/components/Atomic/Organisms/HistoricalAssetHealthDetailOrganism'

import ASSET_HEADER_QUERY from '#/graphql/operations/asset/assetPageHeader.gql'

const OverviewWrapperStyled = styled('div')`
  display: grid;
  min-height: calc(100% - 2rem);
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 1rem;
  margin: 1rem;
  .noData {
    display: flex;
    justify-content: center;
  }
`
export default {
  components: {
    AssetHeaderOrganism,
    HistoricalAssetHealthDetailOrganism,
    OverviewWrapperStyled,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      asset: null,
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
    },
  },
  apollo: {
    asset: {
      query: ASSET_HEADER_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return !this.$route.params.id
      },
    },
  },
}
</script>
