var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', [_c('TimePeriodSwitcherMolecule', {
    attrs: {
      "date": _vm.sickPeriod,
      "unit": 'hour',
      "validDates": _vm.sickHoursOfTheDay
    },
    on: {
      "changeTimePeriod": _vm.onChangeTimePeriod
    }
  }), !_vm.isLoading ? _c('CustomChartAtom', {
    staticClass: "chart",
    attrs: {
      "data": _vm.generateData(),
      "categories": _vm.categories,
      "types": _vm.types,
      "tooltip": _vm.tooltip,
      "seperator": false,
      "showMinSeperatorWithLabel": true
    },
    on: {
      "itemClick": _vm.onItemClick
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }