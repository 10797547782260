var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('LoadingDots') : _c('router-link', {
    class: _vm.styles,
    attrs: {
      "to": _vm.to,
      "target": _vm.target
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }