var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistoricalAssetHealthDetailStyled', {
    attrs: {
      "id": "historicalAssetHealthContainer"
    }
  }, [_c('HistoricalAssetHealthDetailHeaderMolecule'), _c('DetailSectionStyled', [_c('SicknessCardArea', _vm._l(_vm.sicknessCardData, function (sicknessCard) {
    return _c('SicknessCardAtom', {
      key: sicknessCard.id,
      attrs: {
        "trend": sicknessCard.trend,
        "label": sicknessCard.label,
        "date": sicknessCard.date,
        "duration": sicknessCard.duration,
        "variation": sicknessCard.variation,
        "tooltipContent": sicknessCard.tooltip,
        "isLoading": _vm.$apollo.queries.meanTimeToResolveSick.loading || _vm.$apollo.queries.meanTimeBeforeSick.loading
      }
    });
  }), 1), !_vm.monthlyHealthData.length && !_vm.isMonthLoading ? _c('NoDataStyled', [_vm._v(" " + _vm._s(_vm.$tc('messages.noData')) + " ")]) : [_vm.month ? _c('HistoricalAssetMonthlyHealthDataMolecule', {
    key: _vm.startOfMonth,
    attrs: {
      "loading": _vm.isMonthLoading,
      "assetId": _vm.assetId,
      "month": _vm.month,
      "selectedDate": _vm.dayOfMonth,
      "data": _vm.monthlyHealthData
    },
    on: {
      "onDateSelection": _vm.setDate,
      "onMonthChange": _vm.setMonth
    }
  }) : _vm._e(), !_vm.isMonthLoading ? [_vm.hasSickPeriod ? [_vm.dayOfMonth ? _c('HistoricalAssetDailyHealthDataMolecule', {
    key: _vm.startOfDay,
    attrs: {
      "loading": _vm.isDayLoading,
      "assetId": _vm.assetId,
      "dayOfMonth": _vm.dayOfMonth,
      "selectedHour": _vm.hour,
      "data": _vm.dailyHealthData,
      "sickDaysOfTheMonth": _vm.sickDaysOfTheMonth
    },
    on: {
      "onHourSelection": _vm.setHour,
      "onDayChange": _vm.setDate
    }
  }) : _vm._e(), _vm.hour && _vm.assetSicknessSummary ? _c('HistoricalAssetSickPeriodStyled', [_c('HistoricalAssetSickPeriodWrapperMolecule', {
    attrs: {
      "loading": _vm.$apollo.queries.assetIssuesForHistoricalHealth.loading || _vm.$apollo.queries.assetSicknessSummary.loading,
      "sicknessSummary": _vm.assetSicknessSummary,
      "sickPeriod": _vm.hour,
      "assetIssues": _vm.assetIssuesForHistoricalHealth,
      "sickHoursOfTheDay": _vm.sickHoursOfTheDay
    },
    on: {
      "onHourChange": _vm.setHour
    }
  })], 1) : _vm._e()] : _c('NoDataStyled', [_vm._v(" " + _vm._s(_vm.$tc('messages.noSickPeriod')) + " ")])] : _vm._e()], _c('ScrollToTopButtonMolecule', {
    attrs: {
      "containerId": 'app-main',
      "scrollThreshold": 500
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }