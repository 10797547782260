var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OverviewWrapperStyled', [_vm.$apollo.queries.asset.loading ? _c('TalpaLoaderWrapper') : [_vm.asset ? [_c('AssetHeaderOrganism', {
    attrs: {
      "asset": _vm.asset
    }
  }), _c('HistoricalAssetHealthDetailOrganism', {
    attrs: {
      "assetId": _vm.asset.id
    }
  })] : _c('div', {
    staticClass: "noData"
  }, [_vm._v(_vm._s(_vm.$t('messages.noData')))])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }