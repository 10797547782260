<template>
  <CustomChartAtomStyled>
    <VChart ref="temporalChart" :option="option" :updateOptions="{ notMerge: true }" autoresize />
    <template v-if="showMinSeperator">
      <div
        v-for="(left, index) in separatorPositions"
        :key="'separator-' + index"
        class="separator withoutLabel"
        :style="{ left: left + '%' }"
      ></div>
    </template>
    <template v-if="showMinSeperatorWithLabel">
      <div v-for="(label, index) in labeledSeparators" :key="'label-' + index">
        <div
          v-if="index !== 0 && index !== labeledSeparators.length - 1"
          class="separator withLabel"
          :style="{ left: label.left + '%' }"
        ></div>
        <div class="label" :class="{ lastLabel: index === labeledSeparators.length - 1 }" :style="{ left: label.left + '%' }">
          {{ label.text }}
        </div>
      </div>
    </template>
  </CustomChartAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { clipRectByRect } from 'echarts/lib/util/graphic'
import { CustomChart } from 'echarts/charts'
import VChart from 'vue-echarts'
import { TooltipComponent, GridComponent, GraphicComponent } from 'echarts/components'
import chroma from 'chroma-js'

use([TooltipComponent, GridComponent, CustomChart, GraphicComponent, CanvasRenderer])

const CustomChartAtomStyled = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  .separator {
    position: absolute;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.atomic.hourSeperator}; /* Color of the separator */
  }
  .withoutLabel {
    top: 5px;
    height: 1rem;
  }
  .withLabel {
    top: 10px;
    height: 2rem;
  }
  .label {
    position: absolute;
    transform: translate(6%, -50%);
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textActivePrimary};
    top: 3.5rem;
    width: max-content;
  }
  .lastLabel {
    right: 3px;
  }
`

/*
 * This component is a temporal chart that is used to show any data that changes over time.
 * The chart is a custom chart that uses a custom renderer to draw rectangles on the chart.
 * Please refer the custom chart for more infomaion - https://echarts.apache.org/en/tutorial.html#Custom%20Series
 */
export default {
  inject: ['theme', 'uiSettings'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    //How many chart to be displayed horizontally
    categories: {
      type: Array,
      required: true,
    },
    //In each category, what are the types of data to be displayed
    types: {
      type: Array,
      required: true,
    },
    xAxisLabelGenerator: {
      type: Function,
      default: null,
    },
    seperator: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Object,
    },
    showMinSeperator: {
      type: Boolean,
      default: false,
    },
    showMinSeperatorWithLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomChartAtomStyled,
    VChart,
  },
  data() {
    return {
      clickedItemIndex: -1,
      firstSelectionIndex: -1,
    }
  },
  mounted() {
    this.initChart()
    this.$nextTick(() => {
      if (this.firstSelectionIndex !== -1) {
        //only highlight the first item if it is worth selection.
        //In this case, only if we have sick periods
        this.highlightSelectedItem(this.firstSelectionIndex)
      }
    })
  },
  computed: {
    separatorPositions() {
      return [25, 50, 75]
    },
    labeledSeparators() {
      return [
        { left: 0, text: `0 ${this.$tc('shortMinute')}` },
        { left: 25, text: `15 ${this.$tc('shortMinute')}` },
        { left: 50, text: `30 ${this.$tc('shortMinute')}` },
        { left: 75, text: `45 ${this.$tc('shortMinute')}` },
        { text: `60 ${this.$tc('shortMinute')}` },
      ]
    },
    option() {
      return {
        width: '100%',
        height: '100%',
        tooltip: this.tooltip,
        animation: false,
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        xAxis: {
          show: false,
          type: 'time',
        },
        yAxis: {
          show: false,
          data: this.categories,
        },
        series: [
          {
            type: 'custom',
            renderItem: this.renderItem,
            selectedMode: false,
            itemStyle: {
              opacity: 1,
            },
            encode: {
              x: [1, 2],
              y: 0,
            },
            data: this.data,
          },
        ],
      }
    },
  },
  methods: {
    /*
     * This method is responsible for processing each item in data and convert to graphical representation.
     * The method is called for each item in the data array. Refer - https://echarts.apache.org/en/option.html#series-custom.renderItem
     */
    renderItem(params, api) {
      const categoryIndex = api.value(0)
      const start = api.coord([api.value(1), categoryIndex])
      const end = api.coord([api.value(2), categoryIndex])
      const height = api.size([0, 1])[1] * 0.5 //height of the rectangle

      //current index of the item out of total number of temporal data
      const dataIndex = params.dataIndex
      const data = this.data[dataIndex]
      // const borderHightlightWidth = 2
      const borderHightlightColor = data.itemStyle.selection
      const isSick = data.name.toLowerCase() === 'sick'
      const numberOfLines = (end[0] - start[0]) / 5 - 1

      // Check if it's the first or last rectangle and add border radius
      const isFirst = dataIndex === 0
      const isLast = dataIndex === this.data.length - 1
      const showSeperator = this.seperator && !isLast
      const rectShape = clipRectByRect(
        {
          x: start[0] + 1, // + 1 to avoid the left border of the first rectangle being clipped
          y: start[1] - height / 1.5,
          width: end[0] - start[0] - (showSeperator ? 1 : 0), // - 1 to avoid the right border of every rectangle being clipped
          height: height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
          r: params.coordSys.r,
        },
      )
      if (!rectShape) {
        return
      }

      // Calculate slanted line coordinates
      const angle = (-86 * Math.PI) / 180 // Angle in radians
      const startX = rectShape.x
      const startY = rectShape.y + height
      const endX = rectShape.x + rectShape.width
      const endY = rectShape.y + height + rectShape.width * Math.tan(angle)

      const clippedStartX = Math.min(rectShape.x + 5, Math.max(startX, endX))
      const clippedStartY = Math.max(rectShape.y, Math.min(startY, endY))
      const clippedEndX = Math.max(rectShape.x, Math.min(startX, endX))
      const clippedEndY = Math.min(rectShape.y + rectShape.height, Math.max(startY, endY))

      const isClickedItem = data.isSelected
      const itemStyle = isSick
        ? {
            fill: data.itemStyle.color,
            stroke: isClickedItem ? borderHightlightColor : showSeperator ? data.itemStyle.border : data.itemStyle.color,
            lineWidth: isClickedItem ? 2 : 1,
          }
        : {
            fill: data.itemStyle.color,
          }

      let groupChildren = []
      if (isFirst) {
        // adding border radius to the first rectangle
        rectShape.r = [5, 0, 0, 5]
      } else if (isLast) {
        // adding border radius to the last rectangle
        rectShape.r = [0, 5, 5, 0]
      }
      groupChildren.push({
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: itemStyle,
        styleEmphasis: {
          fill: data.itemStyle.color,
          // stroke: data.isFuture || !isSick || !showSeperator ? 'none' : ,
          // lineWidth: data.isFuture || !isSick || !showSeperator ? '1' : borderHightlightWidth,
        },
      })
      if (isSick) {
        // First selection item is here - fire an event
        if (data.isSelected) {
          this.clickedItemIndex = this.firstSelectionIndex = dataIndex
          this.$emit('itemClicked', this.data[dataIndex])
        }
        for (let i = 0; i < numberOfLines; i++) {
          groupChildren.push({
            type: 'line',
            shape: {
              x1: clippedStartX + i * 5,
              y1: clippedStartY,
              x2: clippedEndX + i * 5,
              y2: clippedEndY,
            },
            style: {
              stroke: data.itemStyle.stroke,
              lineWidth: 0.5,
            },
          })
        }
      } else if (this.showSeperator) {
        // Add right border to the rectangle
        groupChildren.push({
          type: 'line',
          shape: {
            x1: rectShape.x + rectShape.width,
            y1: rectShape.y,
            x2: rectShape.x + rectShape.width,
            y2: rectShape.y + rectShape.height,
          },
          style: {
            stroke: data.itemStyle.seperator,
            lineWidth: 1,
          },
        })
      }
      //x-axis labels
      groupChildren.push({
        type: 'text',
        style: {
          text: (this.xAxisLabelGenerator && this.xAxisLabelGenerator(this.data[dataIndex])) || '',
          x: rectShape.x + rectShape.width / 50, // x axis label positioned at the left of the item
          y: rectShape.y + rectShape.height + 5, // y axis label positioned below the end of the item,
          textFill:
            data.name === 'UNKNOWN' ? chroma(this.theme.colors.textActivePrimary).alpha(0.4).css() : this.theme.colors.textActivePrimary,
          fontSize: 11,
        },
      })

      return {
        type: 'group',
        children: groupChildren,
      }
    },

    highlightSelectedItem(dataIndex, updatedData) {
      const seriesData = updatedData || this.data
      const itemClicked = seriesData[dataIndex]
      this.$refs.temporalChart.chart.setOption({
        series: [
          {
            type: 'custom',
            renderItem: this.renderItem,
            selectedMode: false,
            itemStyle: {
              opacity: 1,
            },
            emphasis: true,
            encode: {
              x: [1, 2],
              y: 0,
            },
            data: seriesData,
          },
        ],
      })
      this.$emit('itemClick', itemClicked)
    },
    initChart() {
      this.attachEvent()
    },
    attachEvent() {
      this.$refs.temporalChart.chart.on('click', params => {
        const seriesData = this.$refs.temporalChart.chart.getOption().series[0].data
        if (seriesData[params.dataIndex].isFuture) {
          return
        }
        const prevSelectedItem = seriesData.find(data => data.isSelected)
        if (prevSelectedItem) {
          prevSelectedItem.isSelected = false
        }
        seriesData[params.dataIndex].isSelected = true
        this.clickedItemIndex = params.dataIndex
        this.highlightSelectedItem(this.clickedItemIndex, seriesData)
      })
    },
  },
}
</script>
