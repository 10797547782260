<template>
  <WrapperStyled v-tooltip="tooltip">
    <div class="sickness-header">
      <span class="label"> {{ label }} </span>
      <span class="date">{{ date }}</span>
    </div>
    <div class="sickness-stats">
      <span class="duration">
        <LoadingDots v-if="isLoading" />
        <h3 v-else>{{ duration }}</h3>
      </span>
      <span :class="changeClass">
        <LoadingDots v-if="isLoading" />
        <h3 v-else>{{ variation }}</h3>
      </span>
    </div>
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VTooltip } from 'v-tooltip'
import { LoadingDots } from '@common/components'

const WrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  border: 1px solid ${({ theme }) => theme.colors.atomic.sicknessCardBorder};
  border-radius: 5px;
  width: -webkit-fill-available;
  gap: 0.5rem;
  .sickness-header,
  .sickness-stats {
    display: flex;
    justify-content: space-between;
  }

  .label,
  .date {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .duration,
  .positive,
  .negative,
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    height: 1.5rem;
  }

  .positive {
    color: ${({ theme }) => theme.colors.atomic.positiveTrend};
  }

  .negative {
    color: ${({ theme }) => theme.colors.atomic.negativeTrend};
  }
`
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
    variation: {
      type: String,
      required: true,
    },
    tooltipContent: {
      type: String,
      required: true,
    },
    trend: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WrapperStyled,
    LoadingDots,
  },
  directives: {
    tooltip: VTooltip,
  },
  computed: {
    changeClass() {
      if (this.trend === 'inverse') {
        return { positive: this.variation?.startsWith('+'), negative: this.variation?.startsWith('-') }
      } else {
        return { positive: this.variation?.startsWith('-'), negative: this.variation?.startsWith('+') }
      }
    },
    tooltip() {
      return {
        content: this.tooltipContent,
        placement: 'top',
        classes: 'sicknessMeanTooltip',
        trigger: window.innerWidth < 1024 ? 'click' : 'hover',
        container: false,
      }
    },
  },
}
</script>
