<template>
  <LineClampedTextAtomStyled :linesClamped="linesClamped">
    <div class="clamped-text" ref="clampedText" :class="{ truncate: shouldTruncate }">
      {{ text }}
    </div>
    <a href="#" v-if="isTruncated || (!isTruncated && !shouldTruncate)" @click="shouldTruncate = !shouldTruncate">{{
      shouldTruncate ? $t('actions.showMore') : $t('actions.showLess')
    }}</a>
  </LineClampedTextAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const LineClampedTextAtomStyled = styled('div')`
  .clamped-text {
    display: -webkit-box;
    margin-bottom: 0.5rem;
    &.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: ${({ linesClamped }) => linesClamped};
      -webkit-box-orient: vertical;
    }
  }
`

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    linesClamped: {
      type: Number,
      required: true,
    },
  },
  components: {
    LineClampedTextAtomStyled,
  },
  data() {
    return {
      isTruncated: true,
      shouldTruncate: true,
    }
  },
  mounted() {
    this.checkIsTruncated()
  },
  updated() {
    this.checkIsTruncated()
  },
  methods: {
    checkIsTruncated() {
      this.isTruncated = this.$refs.clampedText?.scrollHeight > this.$refs.clampedText?.clientHeight
    },
  },
}
</script>
