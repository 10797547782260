var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('LineClampedTextAtomStyled', {
    attrs: {
      "linesClamped": _vm.linesClamped
    }
  }, [_c('div', {
    ref: "clampedText",
    staticClass: "clamped-text",
    class: {
      truncate: _vm.shouldTruncate
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _vm.isTruncated || !_vm.isTruncated && !_vm.shouldTruncate ? _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        _vm.shouldTruncate = !_vm.shouldTruncate;
      }
    }
  }, [_vm._v(_vm._s(_vm.shouldTruncate ? _vm.$t('actions.showMore') : _vm.$t('actions.showLess')))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }