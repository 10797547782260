import { DateTime } from 'luxon'
import { getLocaleDate } from '@/utils/time'

export const units = [
  {
    key: 'hour',
    display: date => {
      const startHour = DateTime.fromISO(date.start).hour
      const endHour = DateTime.fromISO(date.end).plus({ hours: 1 }).hour
      return `${startHour}:00 - ${endHour}:00`
    },
  },
  { key: 'day', display: (date, locale) => getLocaleDate(date.start, locale, 'dd MMMM yyyy') },
  { key: 'week', display: (date, locale) => getLocaleDate(date.start, locale, 'MMM yyyy') },
  { key: 'month', display: (date, locale) => getLocaleDate(date.start, locale, 'MMMM yyyy') },
  { key: 'year', display: (date, locale) => getLocaleDate(date.start, locale, 'yyyy') },
]

export const ALLOWED_UNITS = units.map(unit => unit.key)

export function switchPeriod(dateInISO, unit, direction) {
  let changedDate = undefined
  const currentDate = DateTime.fromISO(dateInISO)

  switch (unit) {
    case 'hour':
      changedDate = currentDate.plus({ hours: direction })
      break
    case 'day':
      changedDate = currentDate.plus({ days: direction })
      break
    case 'week':
      changedDate = currentDate.plus({ weeks: direction })
      break
    case 'month':
      changedDate = currentDate.plus({ months: direction })
      break
    case 'year':
      changedDate = currentDate.plus({ years: direction })
      break
    default:
      throw new Error('Invalid unit. Allowed units are: hour, day, week, month, year. Got: ' + unit)
  }

  return changedDate
}

// Switches the date to the next or previous date in the list of valid dates
export function switchDate(dateInISO, direction, validDates) {
  const currentDate = DateTime.fromISO(dateInISO)

  // Find the index of the current date
  const currentIndex = validDates.findIndex(date => date.equals(currentDate))

  if (currentIndex === -1) {
    throw new Error('The current date is not in the list of valid dates.')
  }

  let newIndex
  if (direction === 1) {
    newIndex = currentIndex + 1
  } else if (direction === -1) {
    newIndex = currentIndex - 1
  } else {
    throw new Error('Invalid direction. Allowed directions are: 1, -1. Got: ' + direction)
  }

  if (newIndex >= 0 && newIndex < validDates.length) {
    return validDates[newIndex]
  } else {
    return null
  }
}

export function isFuturePeriod(date, unit) {
  const currentDate = DateTime.now()
  const parsedDate = DateTime.fromISO(date)

  let diffintime
  switch (unit) {
    case 'month':
      diffintime = parsedDate.diff(currentDate, 'months').months
      break
    case 'day':
      diffintime = parsedDate.diff(currentDate, 'days').days
      break
    case 'week':
      diffintime = parsedDate.diff(currentDate, 'weeks').weeks
      break
    case 'hour':
      diffintime = parsedDate.diff(currentDate, 'hours').hours
      break
    case 'year':
      diffintime = parsedDate.diff(currentDate, 'years').years
      break
    default:
      throw new Error('Invalid unit specified')
  }

  return diffintime > 0
}
