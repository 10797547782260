<template>
  <LoadingDots v-if="loading" />
  <router-link :to="to" :class="styles" :target="target" v-else>
    {{ label }}
  </router-link>
</template>

<script>
import { css } from '@emotion/css'
import { LoadingDots } from '@common/components'

const GoToAtomStyles = theme => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 0.5rem;
  color: ${theme.colors.white} !important;
  background: ${theme.colors.primary};
  &:hover {
    background: ${theme.colors.primaryLighter};
  }
`

export default {
  inject: ['theme'],
  props: {
    to: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: '_self',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingDots,
  },
  computed: {
    styles() {
      return GoToAtomStyles(this.theme)
    },
  },
}
</script>
