var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistoricalAssetHourlyHealthDataStyled', [!_vm.isLoading ? _c('CustomChartAtom', {
    staticClass: "chart",
    attrs: {
      "data": _vm.generateData(),
      "categories": _vm.categories,
      "types": _vm.types,
      "tooltip": _vm.tooltip,
      "seperator": false,
      "showMinSeperator": true
    },
    on: {
      "itemClick": _vm.onItemClick
    }
  }) : _vm._e(), _vm.sickPeriod && !_vm.isLoading && _vm.assetIssue.issues.length ? _c('HistoricalAssetSickPeriodDetailMolecule', {
    attrs: {
      "assetIssue": _vm.assetIssue
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }