var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CustomChartAtomStyled', [_c('VChart', {
    ref: "temporalChart",
    attrs: {
      "option": _vm.option,
      "updateOptions": {
        notMerge: true
      },
      "autoresize": ""
    }
  }), _vm.showMinSeperator ? _vm._l(_vm.separatorPositions, function (left, index) {
    return _c('div', {
      key: 'separator-' + index,
      staticClass: "separator withoutLabel",
      style: {
        left: left + '%'
      }
    });
  }) : _vm._e(), _vm.showMinSeperatorWithLabel ? _vm._l(_vm.labeledSeparators, function (label, index) {
    return _c('div', {
      key: 'label-' + index
    }, [index !== 0 && index !== _vm.labeledSeparators.length - 1 ? _c('div', {
      staticClass: "separator withLabel",
      style: {
        left: label.left + '%'
      }
    }) : _vm._e(), _c('div', {
      staticClass: "label",
      class: {
        lastLabel: index === _vm.labeledSeparators.length - 1
      },
      style: {
        left: label.left + '%'
      }
    }, [_vm._v(" " + _vm._s(label.text) + " ")])]);
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }