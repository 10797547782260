<template>
  <HistoricalAssetHealthDetailStyled id="historicalAssetHealthContainer">
    <HistoricalAssetHealthDetailHeaderMolecule />

    <DetailSectionStyled>
      <SicknessCardArea>
        <SicknessCardAtom
          v-for="sicknessCard in sicknessCardData"
          :key="sicknessCard.id"
          :trend="sicknessCard.trend"
          :label="sicknessCard.label"
          :date="sicknessCard.date"
          :duration="sicknessCard.duration"
          :variation="sicknessCard.variation"
          :tooltipContent="sicknessCard.tooltip"
          :isLoading="$apollo.queries.meanTimeToResolveSick.loading || $apollo.queries.meanTimeBeforeSick.loading"
        />
      </SicknessCardArea>

      <NoDataStyled v-if="!monthlyHealthData.length && !isMonthLoading"> {{ $tc('messages.noData') }} </NoDataStyled>
      <template v-else>
        <HistoricalAssetMonthlyHealthDataMolecule
          :loading="isMonthLoading"
          :key="startOfMonth"
          :assetId="assetId"
          :month="month"
          :selectedDate="dayOfMonth"
          :data="monthlyHealthData"
          @onDateSelection="setDate"
          @onMonthChange="setMonth"
          v-if="month"
        />

        <template v-if="!isMonthLoading">
          <template v-if="hasSickPeriod">
            <HistoricalAssetDailyHealthDataMolecule
              v-if="dayOfMonth"
              :loading="isDayLoading"
              :key="startOfDay"
              :assetId="assetId"
              :dayOfMonth="dayOfMonth"
              :selectedHour="hour"
              :data="dailyHealthData"
              :sickDaysOfTheMonth="sickDaysOfTheMonth"
              @onHourSelection="setHour"
              @onDayChange="setDate"
            />
            <HistoricalAssetSickPeriodStyled v-if="hour && assetSicknessSummary">
              <HistoricalAssetSickPeriodWrapperMolecule
                :loading="$apollo.queries.assetIssuesForHistoricalHealth.loading || $apollo.queries.assetSicknessSummary.loading"
                :sicknessSummary="assetSicknessSummary"
                :sickPeriod="hour"
                :assetIssues="assetIssuesForHistoricalHealth"
                :sickHoursOfTheDay="sickHoursOfTheDay"
                @onHourChange="setHour"
              />
            </HistoricalAssetSickPeriodStyled>
          </template>
          <NoDataStyled v-else>
            {{ $tc('messages.noSickPeriod') }}
          </NoDataStyled>
        </template>
      </template>
      <ScrollToTopButtonMolecule :containerId="'app-main'" :scrollThreshold="500" />
    </DetailSectionStyled>
  </HistoricalAssetHealthDetailStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ScrollToTopButtonMolecule } from '@common/components'
import HistoricalAssetMonthlyHealthDataMolecule from '@/components/Atomic/Molecules/HistoricalAssetHealth/HistoricalAssetMonthlyHealthDataMolecule'
import HistoricalAssetDailyHealthDataMolecule from '@/components/Atomic/Molecules/HistoricalAssetHealth/HistoricalAssetDailyHealthDataMolecule'
import HistoricalAssetHealthDetailHeaderMolecule from '@/components/Atomic/Molecules/HistoricalAssetHealth/HistoricalAssetHealthDetailHeaderMolecule'
import HistoricalAssetSickPeriodWrapperMolecule from '@/components/Atomic/Molecules/HistoricalAssetHealth/HistoricalAssetSickPeriodWrapperMolecule'
import { DateTime, Duration } from 'luxon'
import { getLocale } from '@/utils/locale'
import { getIntervalBetweenDates, getMonthAndYearFormatted } from '@/utils/time'
import SicknessCardAtom from '@/components/Atomic/Atoms/SicknessCardAtom'

import ASSET_HISTORICAL_MONTHLY_HEALTH_DATA_QUERY from '#/graphql/operations/historicalAssetHealth/historicalAssetMonthlyHealthDataQuery.gql'
import ASSET_HISTORICAL_DAILY_HEALTH_DATA_QUERY from '#/graphql/operations/historicalAssetHealth/historicalAssetDailyHealthDataQuery.gql'
import ASSET_SICK_PERIOD_QUERY from '#/graphql/operations/historicalAssetHealth/sickPeriodQuery.gql'
import MEAN_TIME_TO_RESOLVE_SICKNESS_QUERY from '#/graphql/operations/historicalAssetHealth/meanTimeToResolveSickQuery.gql'
import MEAN_TIME_BEFORE_SICKNESS_QUERY from '#/graphql/operations/historicalAssetHealth/meanTimeBeforeSickQuery.gql'
import ASSET_SICKNESS_SUMMARY_QUERY from '#/graphql/operations/historicalAssetHealth/assetSicknessSummary.gql'

const HistoricalAssetHealthDetailStyled = styled('div')`
  border-radius: 8px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
`
const HistoricalAssetSickPeriodStyled = styled('div')`
  padding: 2rem 0 1rem 0;
  border-top: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-items: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
const SicknessCardArea = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
  gap: 1rem;
`
const DetailSectionStyled = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.5rem;
`
export default {
  inject: ['uiSettings'],
  props: {
    assetId: {
      type: String,
      required: true,
    },
  },
  components: {
    NoDataStyled,
    HistoricalAssetHealthDetailStyled,
    HistoricalAssetSickPeriodStyled,
    HistoricalAssetMonthlyHealthDataMolecule,
    HistoricalAssetDailyHealthDataMolecule,
    HistoricalAssetHealthDetailHeaderMolecule,
    HistoricalAssetSickPeriodWrapperMolecule,
    ScrollToTopButtonMolecule,
    SicknessCardArea,
    SicknessCardAtom,
    DetailSectionStyled,
  },
  data() {
    return {
      month: null,
      dayOfMonth: null,
      hour: null,
      monthlyHealthData: [],
      dailyHealthData: [],
      assetIssuesForHistoricalHealth: [],
      hasSickPeriod: false,
      meanTimeToResolveSick: null,
      meanTimeBeforeSick: null,
      assetSicknessSummary: null,
    }
  },
  mounted() {
    const { sickPeriod } = this.$route.query
    // Initialize month data based on query parameters
    if (sickPeriod) {
      const startDate = DateTime.fromMillis(Number(sickPeriod))
      this.month = this.getCurrentMonthStartEndDates(startDate)
    } else {
      this.month = this.getCurrentMonthStartEndDates(DateTime.local())
    }
  },

  computed: {
    startOfMonth() {
      return DateTime.fromISO(this.month?.start).startOf('month').toMillis() + Math.random()
    },
    startOfDay() {
      return DateTime.fromISO(this.dayOfMonth?.start).startOf('day').toMillis() + Math.random()
    },
    isMonthLoading() {
      return this.$apollo.queries.monthlyHealthData.loading
    },
    isDayLoading() {
      return this.$apollo.queries.monthlyHealthData.loading || this.$apollo.queries.dailyHealthData.loading
    },
    isSickPeriodLoading() {
      return (
        this.$apollo.queries.monthlyHealthData.loading ||
        this.$apollo.queries.dailyHealthData.loading ||
        this.$apollo.queries.assetIssuesForHistoricalHealth.loading
      )
    },
    sicknessCardData() {
      return [
        {
          id: 'mtbf',
          label: this.$t('historicalAssetHealth.mtbf'),
          date: this.getMonthAndYear(),
          duration: this.meanTimeBeforeSick?.value ? this.convertSeconds(this.meanTimeBeforeSick.value) : '-',
          variation: this.meanTimeBeforeSick?.deltaValue || '-',
          tooltip: this.$t('historicalAssetHealth.tooltip.mttbs'),
          trend: 'inverse',
        },
        {
          id: 'mttr',
          label: this.$t('historicalAssetHealth.mttr'),
          date: this.getMonthAndYear(),
          duration: this.meanTimeToResolveSick?.value ? this.convertSeconds(this.meanTimeToResolveSick.value) : '-',
          variation: this.meanTimeToResolveSick?.deltaValue || '-',
          tooltip: this.$t('historicalAssetHealth.tooltip.mttrs'),
          trend: '',
        },
      ]
    },
    sickDaysOfTheMonth() {
      return this.monthlyHealthData.filter(data => data.status === 'SICK').map(data => data.start)
    },
    sickHoursOfTheDay() {
      return this.dailyHealthData.filter(data => data.status === 'SICK').map(data => data.start)
    },
    getStartOfMonth() {
      return DateTime.fromISO(this.month?.start).startOf('month')
    },
  },
  methods: {
    getMonthAndYear() {
      const locale = getLocale(this.uiSettings?.dates)
      return getMonthAndYearFormatted(this.month?.start, locale)
    },
    setMonth(params) {
      this.month = params
      this.resetDayAndHour()
      this.updateRouterQuery(params)
    },
    setDate(params) {
      if (!params.healthData || params.healthData.status.toLowerCase() === 'sick') {
        this.dayOfMonth = { start: params.start, end: params.end }
        this.resetHour()
      }
    },
    setHour(params) {
      if (!params.healthData || params.healthData.status.toLowerCase() === 'sick') {
        this.hour = { start: params.start, end: params.end }
      }
    },
    resetDayAndHour() {
      this.dayOfMonth = null
      this.hour = null
    },
    resetHour() {
      this.hour = null
    },
    updateRouterQuery(period) {
      this.$router.push({ query: { sickPeriod: Date.parse(period.start) } })
    },
    getCurrentMonthStartEndDates(currentDate) {
      return this.getMonthDates(currentDate)
    },
    getMonthDates(date) {
      const startOfMonth = date.startOf('month')
      const endOfMonth = date.endOf('month')

      return {
        start: startOfMonth.toISO(),
        end: endOfMonth.toISO(),
      }
    },
    convertSeconds(seconds) {
      const duration = Duration.fromObject({ seconds })

      const months = duration.as('months').toFixed(1)
      if (months >= 1) {
        return `${months} ${this.$tc('times.month', months)}`
      }

      const days = duration.as('days').toFixed(1)
      if (days >= 1) {
        return `${days} ${this.$tc('times.day', days)}`
      }

      const hours = duration.as('hours').toFixed(1)
      if (hours >= 1) {
        return `${hours} ${this.$tc('times.hour', hours)}`
      }

      const minutes = duration.as('minutes').toFixed(1)
      if (minutes >= 1) {
        return `${minutes} ${this.$tc('times.minute', minutes)}`
      }
      const roundedSeconds = seconds.toFixed(1)
      return `${roundedSeconds} ${this.$tc('times.second', roundedSeconds)}`
    },
  },

  apollo: {
    monthlyHealthData: {
      query: ASSET_HISTORICAL_MONTHLY_HEALTH_DATA_QUERY,
      manual: true,
      variables() {
        return {
          where: {
            interval: getIntervalBetweenDates(this.month.start, this.month.end).toISO(),
            assetId: this.assetId,
            granularity: 'P1D',
            timezone: DateTime.local().zoneName,
          },
        }
      },
      skip() {
        return !this.assetId || !this.month
      },
      result({ data }) {
        this.monthlyHealthData = data.monthlyHealthData?.healthWindows
        this.hasSickPeriod = this.monthlyHealthData.some(data => data.status === 'SICK')
      },
    },
    dailyHealthData: {
      query: ASSET_HISTORICAL_DAILY_HEALTH_DATA_QUERY,
      manual: true,
      variables() {
        return {
          where: {
            interval: getIntervalBetweenDates(this.dayOfMonth.start, this.dayOfMonth.end).toISO(),
            assetId: this.assetId,
            granularity: 'PT1H',
            timezone: DateTime.local().zoneName,
          },
        }
      },
      skip() {
        return !this.assetId || !this.dayOfMonth || !this.monthlyHealthData?.length
      },
      result({ data }) {
        this.dailyHealthData = data.dailyHealthData?.healthWindows
        const hasSickPeriod = this.dailyHealthData.some(data => data.status === 'SICK')
        if (!hasSickPeriod) {
          this.hour = null
        }
      },
    },
    assetIssuesForHistoricalHealth: {
      query: ASSET_SICK_PERIOD_QUERY,
      variables() {
        return {
          where: {
            interval: getIntervalBetweenDates(this.hour.start, this.hour.end).toISO(),
            assetIds: [this.assetId],
            locale: getLocale(this.uiSettings?.dates),
          },
        }
      },
      skip() {
        return !this.assetId || !this.hour || this.dailyHealthData?.length === 0
      },
    },

    assetSicknessSummary: {
      query: ASSET_SICKNESS_SUMMARY_QUERY,
      variables() {
        return {
          where: {
            assetId: this.assetId,
            interval: getIntervalBetweenDates(this.hour.start, this.hour.end).toISO(),
          },
        }
      },
      skip() {
        return !this.assetId || !this.hour || this.dailyHealthData?.length === 0
      },
    },

    meanTimeToResolveSick: {
      query: MEAN_TIME_TO_RESOLVE_SICKNESS_QUERY,
      variables() {
        return {
          where: {
            fromTs: this.getStartOfMonth,
            assetId: this.assetId,
            timezone: DateTime.local().zoneName,
          },
        }
      },
      skip() {
        return !this.assetId || this.month === null || !this.getStartOfMonth
      },
    },
    meanTimeBeforeSick: {
      query: MEAN_TIME_BEFORE_SICKNESS_QUERY,
      variables() {
        return {
          where: {
            fromTs: this.getStartOfMonth,
            assetId: this.assetId,
            timezone: DateTime.local().zoneName,
          },
        }
      },
      skip() {
        return !this.assetId || this.month === null || !this.getStartOfMonth
      },
    },
  },
}
</script>
