<template>
  <TextSectionMoleculeStyled>
    <TitleWithBorderAtom :title="title" />
    <div class="text">
      <VueMarkdown v-if="isMarkdown" :source="text" />
      <LineClampedTextAtom v-else :text="text" :linesClamped="linesClamped" />
    </div>
  </TextSectionMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import TitleWithBorderAtom from '@/components/Atomic/Atoms/TitleWithBorderAtom'
import LineClampedTextAtom from '@/components/Atomic/Atoms/LineClampedTextAtom'

import VueMarkdown from 'vue-markdown'

const TextSectionMoleculeStyled = styled('div')`
  > .text {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    linesClamped: {
      type: Number,
      default: 3,
    },
    isMarkdown: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextSectionMoleculeStyled,
    TitleWithBorderAtom,
    LineClampedTextAtom,
    VueMarkdown,
  },
}
</script>
