var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHeaderStyled', [_c('div', {
    staticClass: "row1"
  }, [_c('AssetNameMolecule', {
    staticClass: "name",
    attrs: {
      "typeName": _vm.asset.type.name,
      "assetName": _vm.asset.name
    }
  })], 1), _c('div', {
    staticClass: "row2"
  }, [_c('AssetDetailsMolecule', {
    staticClass: "details",
    attrs: {
      "asset": _vm.assetDataWithIntegratedDate,
      "isIntegratedDateLoading": _vm.isIntegrationDateLoading
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }