var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimePeriodSwitcherStyled', [_c('IconStyled', {
    class: {
      disabled: _vm.disablePrevNav
    },
    attrs: {
      "data-testid": "prev-timePeriod-".concat(_vm.unit)
    },
    on: {
      "click": function click($event) {
        return _vm.switchPeriod(-1);
      }
    }
  }, [_c('ChevronLeftIcon', {
    staticClass: "icon prev"
  })], 1), _c('strong', {
    staticClass: "date",
    attrs: {
      "data-testid": "value-timePeriod-".concat(_vm.unit)
    }
  }, [_vm._v(_vm._s(_vm.displayDate))]), _c('IconStyled', {
    class: {
      disabled: _vm.disableNextNav
    },
    attrs: {
      "data-testid": "next-timePeriod-".concat(_vm.unit)
    },
    on: {
      "click": function click($event) {
        return _vm.switchPeriod(1);
      }
    }
  }, [_c('ChevronRightIcon', {
    staticClass: "icon next"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }