var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistoricalAssetHealthDetailHeaderStyled', {
    attrs: {
      "id": "sickness-info"
    }
  }, [_c('div', {
    staticClass: "header-row full-width"
  }, [_c('div', {
    staticClass: "item heading"
  }, [_vm._v(_vm._s(_vm.$t('historicalAssetHealth.title')))]), _c('span', {
    staticClass: "item health-status"
  }, [_c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "legend healthy"
  }), _c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t('historicalAssetHealth.status.healthy')))])]), _c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "legend sick"
  }, [_c('span', {
    staticClass: "stripe"
  }), _c('span', {
    staticClass: "stripe"
  }), _c('span', {
    staticClass: "stripe"
  }), _c('span', {
    staticClass: "stripe"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t('historicalAssetHealth.status.sick')))])]), _c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "legend unknown"
  }), _c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t('historicalAssetHealth.status.unknown')))])]), _c('div', {
    staticClass: "info"
  }, [_c('VPopover', {
    staticClass: "sicknessPopover",
    attrs: {
      "placement": 'bottom-end',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions,
      "popoverClass": 'sicknessDetailsPopOver',
      "container": '#sickness-info',
      "trigger": 'hover'
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverInnerWrapperStyled', [_c('div', {
          staticClass: "content"
        }, [_c('div', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('historicalAssetHealth.tooltip.healthyPeriod.info'))
          }
        })]), _c('div', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('historicalAssetHealth.tooltip.sickPeriod.info'))
          }
        })]), _c('div', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('historicalAssetHealth.tooltip.unknownPeriod.info'))
          }
        })])])])];
      },
      proxy: true
    }])
  }, [_c('InfoIcon', {
    staticClass: "icon",
    attrs: {
      "size": "20"
    }
  })], 1)], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }