<template>
  <AssetHeaderStyled>
    <div class="row1">
      <AssetNameMolecule class="name" :typeName="asset.type.name" :assetName="asset.name" />
    </div>
    <div class="row2">
      <AssetDetailsMolecule class="details" :asset="assetDataWithIntegratedDate" :isIntegratedDateLoading="isIntegrationDateLoading" />
    </div>
  </AssetHeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import AssetNameMolecule from '@/components/Atomic/Molecules/AssetPageHeader/AssetNameMolecule'
import AssetDetailsMolecule from '@/components/Atomic/Molecules/AssetPageHeader/AssetDetailsMolecule'

import ASSET_INTEGRATION_DATE_QUERY from '#/graphql/operations/asset/assetIntegrationDate.gql'

const AssetHeaderStyled = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 0.6fr;
  border: 1px solid ${({ theme }) => theme.colors.mainBG};
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 8px;
  border: solid 0.5px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  .row1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'name';
    border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    align-items: center;
    padding: 0.5rem 1rem;
  }
  .row2 {
    display: grid;
    grid-template-columns: 58px 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'icon details details details details';
    padding: 0.5rem 1rem;
  }
  .name {
    grid-area: name;
  }
  .details {
    grid-area: details;
  }
  @media (max-width: 1024px) {
    .row2 {
      grid-template-columns: 1fr 1fr min-content;
      grid-template-areas:
        'details details details'
        'details details details';
      border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    }
  }
  @media (max-width: 820px) {
    .row2 {
      grid-template-columns: 1fr;
      grid-template-areas:
        'details'
        'details'
        'details'
        'details';
      .details {
        column-gap: 1rem;
      }
    }
  }
`
export default {
  inject: ['uiSettings'],
  components: {
    AssetHeaderStyled,
    AssetNameMolecule,
    AssetDetailsMolecule,
  },
  props: {
    asset: {
      type: Object,
    },
  },
  data() {
    return {
      isIntegrationDateLoading: false,
      assetIntegrationDate: null,
    }
  },
  created() {
    if (!this.asset?.currentLoggerRelation) {
      this.fetchAssetIntegrationDate()
    }
  },
  computed: {
    assetDataWithIntegratedDate() {
      return {
        ...this.asset,
        integratedSince: this.asset?.currentLoggerRelation?.fromTime ?? this.assetIntegrationDate,
      }
    },
  },
  methods: {
    async fetchAssetIntegrationDate() {
      if (!this.asset?.id) return
      try {
        this.isIntegrationDateLoading = true
        const { data } = await this.$apollo.query({
          query: ASSET_INTEGRATION_DATE_QUERY,
          variables: {
            assetId: this.asset?.id,
          },
        })
        this.assetIntegrationDate = data.assetIntegrationDate?.integratedSince ?? null
      } catch (err) {
        this.isIntegrationDateLoading = false
        this.assetIntegrationDate = null
      } finally {
        this.isIntegrationDateLoading = false
      }
    },
  },
}
</script>
